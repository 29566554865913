import React from "react"
import NuvoImage from "./NuvoImage"
import PropTypes from "prop-types"

export default function ResponsiveImage(props) {
    return (
        <>
            <NuvoImage
                useAR
                wrapperClassName="show-desktop-only"
                cloudName="nuvolum"
                publicId={props.desktopImageId}
                className="show-desktop-only"
                width="auto"
                responsive
            ></NuvoImage>
            <NuvoImage
                useAR
                wrapperClassName="show-desktop-only-inverse"
                cloudName="nuvolum"
                publicId={props.mobileImageId}
                className="show-desktop-only-inverse"
                width="auto"
                responsive
            // style={{ paddingTop: "68px" }}
            ></NuvoImage>
        </>
    )
}

ResponsiveImage.propTypes = {
    desktopImageId: PropTypes.string.isRequired,
    mobileImageId: PropTypes.string.isRequired
}